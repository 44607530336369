<template>
  <div ref="detailedContainer" class="detailed-container" @scroll="scrollElement">
    <div ref="header" class="header">
      <div class="back" @click="goBack">
        <div class="back-icon"></div>
      </div>
      <div class="title">&nbsp;</div>
    </div>
    <div ref="content" class="content">
      <div class="user-box">
        <div class="user-head"></div>
        <div class="user-name">{{ bagInfo.nickname }}的红包</div>
      </div>
      <div v-if="bagInfo.is_receive" class="get-amount">
        <div class="amount">{{ bagInfo.red_package_amount }}</div>
        <div class="unit">元</div>
      </div>
      <div v-else class="get-amount">
        <div class="tips">{{ bagInfo.title }}</div>
      </div>
      <div class="set-bag">{{ bagInfo.content }}</div>
      <div class="get-detailed">已领取{{ bagInfo.receive_count }}/{{ bagInfo.count }}个，共{{ bagInfo.receive_amount }}/{{ bagInfo.amount }}元</div>
      <div class="border"></div>
      <div class="get-baglist">
        <div v-for="(item, index) in receive_list" :key="index" class="bag-item">
          <div class="item-box">
            <div class="user-detailed">
              <div class="user-head"></div>
              <div class="time-name">
                <div class="user-name">{{ item.role_name }}</div>
                <div class="user-time">{{ item.receive_time }}</div>
              </div>
            </div>
            <div class="amount-info">{{ item.red_package_amount }}元</div>
          </div>
          <div class="item-border"></div>
        </div>
      </div>
      <!-- <div v-if="isLoaded" class="bottom-tips">我也是有底线的 ~</div> -->
    </div>
    <Loading v-if="isLoading" class="loading-box" type="spinner" />
  </div>
</template>

<script>
import { getRedBagReceiveDetail } from '@/api/redBag';
import { Loading } from 'vant';
import { getDeviceType } from '@/utils';

export default {
  name: 'BagDetailed',
  components: {
    Loading,
  },
  data() {
    return {
      app_id: window.sessionStorage.getItem('appid'),
      token: window.sessionStorage.getItem('ato'),
      redbag_id: null,
      isLoading: false, //是否显示loading
      isLoaded: false, //是否继续加载
      last_id: 0,
      receive_list: [],
      bagInfo: {},
    };
  },
  activated() {
    this.redbag_id = this.$route.query.redbag_id;
    //重置列表参数
    this.resetListParams();
    this.getDetailed();
  },
  methods: {
    //重置列表参数
    resetListParams() {
      this.receive_list.splice(0, this.receive_list.length);
      this.isLoading = false;
      this.isLoaded = false;
      this.last_id = 0;
    },
    scrollElement(e) {
      const scrollTop = e.target.scrollTop; //滚动距离
      const contaienrHeight = this.$refs['detailedContainer'].clientHeight; //视口高度
      const listHeight = this.$refs['header'].clientHeight + this.$refs['content'].clientHeight;
      if (scrollTop > listHeight - contaienrHeight - 5) {
        console.log('到底部了', this.isLoading);
        if (this.isLoading) return;
        if (this.isLoaded) return; //没有数据了
        this.last_id++;
        this.getDetailed();
      }
    },
    goBack() {
      this.$router.go(-1);
      this.bagInfo = {};
      this.receive_list.splice(0, this.receive_list.length);
    },
    getDetailed() {
      this.isLoading = true;
      getRedBagReceiveDetail({
        app_id: this.app_id,
        token: this.token,
        redbag_id: this.redbag_id,
        last_id: this.last_id,
        os: getDeviceType(),
        channel_id: window.sessionStorage.getItem('channel_id'),
        pf: window.sessionStorage.getItem('pf'),
      }).then((res) => {
        console.log('领取详情', res);
        if (res.data.status === 200) {
          this.receive_list.push(...res.data.data.receive_list);
          Object.assign(this.bagInfo, res.data.data);
          if (res.data.data.receive_list.length < 15) {
            this.isLoaded = true;
          }
        } else {
          this.$toast(res.data.msg);
        }
        this.isLoading = false;
      });
    },
  },
};
</script>

<style lang="less" scoped>
// div {
//   box-sizing: border-box;
// }
.detailed-container {
  background-color: #f7f7f7;
  padding: 0 0.28rem;
  height: 100vh;
  overflow-y: auto;
  min-height: 100%;
  .header {
    position: relative;
    .back {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 1rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0 0.36rem;
      .back-icon {
        width: 0.2rem;
        height: 0.29rem;
        background-image: url('../../assets/imgs/back.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
    .title {
      text-align: center;
      font-size: 0.34rem;
      font-weight: 500;
      color: #222222;
      line-height: 1.35rem;
      height: 1.35rem;
    }
  }
  .content {
    padding: 0 0.32rem;
    background: #fefefe;
    box-shadow: 0px 0.02rem 0.01rem 0px rgba(200, 200, 200, 0.1);
    border-radius: 0.05rem;
    .user-box {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 0.24rem;
      .user-head {
        width: 0.4rem;
        height: 0.4rem;
        background-image: url('../../assets/imgs/logo.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center center;
        border-radius: 50%;
        overflow: hidden;
      }
      .user-name {
        font-size: 0.34rem;
        font-weight: 500;
        color: #222222;
        line-height: 0.4rem;
      }
    }
    .get-amount {
      display: flex;
      align-items: baseline;
      justify-content: center;
      margin-top: 0.5rem;
      .tips {
        font-size: 0.48rem;
        font-weight: 500;
        color: #e99732;
      }
      .amount {
        font-size: 0.7rem;
        font-weight: bold;
        color: #e99732;
        margin-right: 0.16rem;
      }
      .unit {
        font-size: 0.3rem;
        font-weight: 500;
        color: #e99732;
      }
    }
    .set-bag {
      margin-top: 0.3rem;
      text-align: center;
      font-size: 0.3rem;
      font-weight: 500;
      color: #bda574;
    }
    .get-detailed {
      margin-top: 0.64rem;
      font-size: 0.26rem;
      font-weight: 400;
      color: #8d8d8d;
    }
    .border {
      height: 0.02rem;
      // margin: 0 0.43rem;
      margin-top: 0.15rem;
      background-image: url('../../assets/imgs/split-border.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center center;
    }
    .get-baglist {
      min-height: 70vh;
      .bag-item {
        .item-box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0.3rem 0 0.26rem;
          .user-detailed {
            display: flex;
            align-items: center;
            .user-head {
              width: 0.6rem;
              height: 0.6rem;
              background-image: url('../../assets/imgs/logo.png');
              background-size: 100% 100%;
              background-repeat: no-repeat;
              background-position: center center;
              border-radius: 50%;
              overflow: hidden;
              margin-right: 0.1rem;
            }
            .time-name {
              display: flex;
              justify-content: space-between;
              flex-direction: column;
              height: 0.66rem;
              .user-name {
                font-size: 0.3rem;
                font-weight: 400;
                color: #222222;
                line-height: 0.3rem;
              }
              .user-time {
                font-size: 0.26rem;
                font-weight: 400;
                color: #8d8d8d;
                line-height: 0.26rem;
              }
            }
          }
          .amount-info {
            font-size: 0.3rem;
            font-weight: 400;
            color: #222222;
            line-height: 0.3rem;
          }
        }
        .item-border {
          height: 0.02rem;
          background-image: url('../../assets/imgs/split-border.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          background-position: center center;
        }
      }
    }
    .bottom-tips {
      text-align: center;
      font-size: 0.28rem;
      padding-top: 0.2rem;
      padding-bottom: 0.2rem;
      border-top: 1px solid rgba(0, 0, 0, 0.06);
    }
  }
}
.loading-box {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}
</style>
